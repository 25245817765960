import React from "react"

import Layout from "../components/layout/layout"

import "../styles/gdpr.scss"

const IndexPage = () => (
  <Layout title="Privacy Policy">
    <main>
      <article>
        <div>
          <div>
            <p>
              Your privacy is important to me. It is dragonia.org.pl policy to
              respect your privacy regarding any information I may collect from
              you across my website,{" "}
              <a href="https://www.dragonia.org.pl">
                https://www.dragonia.org.pl
              </a>
              , and other sites I own and operate.
            </p>
            <h3>1. Information I collect</h3>
            <h4>Log data</h4>
            <p>
              When you visit my website, my servers may automatically log the
              standard data provided by your web browser. It may include your
              computer’s Internet Protocol (IP) address, your browser type and
              version, the pages you visit, the time and date of your visit, the
              time spent on each page, and other details.
            </p>
            <h3>2. Legal bases for processing</h3>
            <p>
              I will process your personal information lawfully, fairly and in a
              transparent manner. I collect and process information about you
              only where I have legal bases for doing so.
            </p>
            <p>
              These legal bases depend on the services you use and how you use
              them, meaning I collect and use your information only where:
            </p>
            <ul>
              <li>
                it’s necessary for the performance of a contract to which you
                are a party or to take steps at your request before entering
                into such a contract (for example, when I provide a service you
                request from me);
              </li>
              <li>
                it satisfies a legitimate interest (which is not overridden by
                your data protection interests), such as for research and
                development, to market and promote my services, and to protect
                my legal rights and interests;
              </li>
              <li>
                you give me consent to do so for a specific purpose (for
                example, you might consent to me sending you my newsletter); or
              </li>
              <li>
                I need to process your data to comply with a legal obligation.
              </li>
            </ul>
            <p>
              Where you consent to my use of information about you for a
              specific purpose, you have the right to change your mind at any
              time (but this will not affect any processing that has already
              taken place).
            </p>
            <p>
              I don’t keep personal information for longer than is necessary.
              While I retain this information, I will protect it within
              commercially acceptable means to prevent loss and theft, as well
              as unauthorised access, disclosure, copying, use or modification.
              That said, I advise that no method of electronic transmission or
              storage is 100% secure and cannot guarantee absolute data
              security. If necessary, I may retain your personal information for
              my compliance with a legal obligation or in order to protect your
              vital interests or the vital interests of another natural person.
            </p>
            <h3>3. Collection and use of information</h3>
            <p>
              I may collect, hold, use and disclose information for the
              following purposes and personal information will not be further
              processed in a manner that is incompatible with these purposes:
            </p>
            <ul>
              <li>
                to enable you to customise or personalise your experience of my
                website;
              </li>
              <li>
                to enable you to access and use my website, associated
                applications and associated social media platforms;
              </li>
              <li>to contact and communicate with you;</li>
              <li>for internal record keeping and administrative purposes;</li>
              <li>
                for analytics, market research and business development,
                including to operate and improve my website, associated
                applications and associated social media platforms;
              </li>
              <li>
                to run competitions and/or offer additional benefits to you;
              </li>
              <li>
                for advertising and marketing, including to send you promotional
                information about my products and services and information about
                third parties that I consider may be of interest to you;
              </li>
              <li>
                to comply with my legal obligations and resolve any disputes
                that I may have; and
              </li>
              <li>to consider your employment application.</li>
            </ul>
            <h3>4. Disclosure of personal information to third parties</h3>
            <p>I may disclose personal information to:</p>
            <ul>
              <li>
                third party service providers for the purpose of enabling them
                to provide their services, including (without limitation) IT
                service providers, data storage, hosting and server providers,
                ad networks, analytics, error loggers, debt collectors,
                maintenance or problem-solving providers, marketing or
                advertising providers, professional advisory and payment systems
                operators;
              </li>
              <li>
                courts, tribunals, regulatory authorities and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish,
                exercise or defend my legal rights;
              </li>
              <li>
                third parties, including agents or sub-contractors, who assist
                us in providing information, products, services or direct
                marketing to you; and
              </li>
              <li>third parties to collect and process data.</li>
            </ul>
            <h3>5. International transfers of personal information</h3>
            <p>
              The personal information I collect is stored and processed where
              me or my partners, affiliates and third-party providers maintain
              facilities. By providing us with your personal information, you
              consent to the disclosure to these overseas third parties.
            </p>
            <p>
              We will ensure that any transfer of personal information from
              countries in the European Economic Area (EEA) to countries outside
              the EEA will be protected by appropriate safeguards, for example
              by using standard data protection clauses approved by the European
              Commission, or the use of binding corporate rules or other legally
              accepted means.
            </p>
            <p>
              Where I transfer personal information from a non-EEA country to
              another country, you acknowledge that third parties in other
              jurisdictions may not be subject to similar data protection laws
              to the ones in my jurisdiction. There are risks if any such third
              party engages in any act or practice that would contravene the
              data privacy laws in my jurisdiction and this might mean that you
              will not be able to seek redress under my jurisdiction’s privacy
              laws.
            </p>
            <h3>6. Your rights and controlling your personal information</h3>
            <p>
              <strong>Choice and consent:</strong> By providing personal
              information to us, you consent to us collecting, holding, using
              and disclosing your personal information in accordance with this
              privacy policy. If you are under 16 years of age, you must have,
              and warrant to the extent permitted by law to us, that you have
              your parent or legal guardian’s permission to access and use the
              website and they (your parents or guardian) have consented to you
              providing us with your personal information. You do not have to
              provide personal information to us, however, if you do not, it may
              affect your use of this website or the products and/or services
              offered on or through it.
            </p>
            <p>
              <strong>Information from third parties:</strong> If I receive
              personal information about you from a third party, I will protect
              it as set out in this privacy policy. If you are a third party
              providing personal information about somebody else, you represent
              and warrant that you have such person’s consent to provide the
              personal information to us.
            </p>
            <p>
              <strong>Restrict:</strong> You may choose to restrict the
              collection or use of your personal information. If you have
              previously agreed to us using your personal information for direct
              marketing purposes, you may change your mind at any time by
              contacting us using the details below. If you ask us to restrict
              or limit how I process your personal information, I will let you
              know how the restriction affects your use of my website or
              products and services.
            </p>
            <p>
              <strong>Access and data portability:</strong> You may request
              details of the personal information that I hold about you. You may
              request a copy of the personal information I hold about you. Where
              possible, I will provide this information in CSV format or other
              easily readable machine format. You may request that we erase the
              personal information I hold about you at any time. You may also
              request that I transfer this personal information to another third
              party.
            </p>
            <p>
              <strong>Correction:</strong> If you believe that any information I
              hold about you is inaccurate, out of date, incomplete, irrelevant
              or misleading, please contact us using the details below. We will
              take reasonable steps to correct any information found to be
              inaccurate, incomplete, misleading or out of date.
            </p>
            <p>
              <strong>Notification of data breaches:</strong> We will comply
              laws applicable to us in respect of any data breach.
            </p>
            <p>
              <strong>Complaints:</strong> If you believe that I have breached a
              relevant data protection law and wish to make a complaint, please
              contact us using the details below and provide us with full
              details of the alleged breach. We will promptly investigate your
              complaint and respond to you, in writing, setting out the outcome
              of my investigation and the steps I will take to deal with your
              complaint. You also have the right to contact a regulatory body or
              data protection authority in relation to your complaint.
            </p>
            <p>
              <strong>Unsubscribe:</strong> To unsubscribe from my e-mail
              database or opt-out of communications (including marketing
              communications), please contact us using the details below or
              opt-out using the opt-out facilities provided in the
              communication.
            </p>
            <h3>7. Cookies</h3>
            <p>
              We use “cookies” to collect information about you and your
              activity across my site. A cookie is a small piece of data that my
              website stores on your computer, and accesses each time you visit,
              so I can understand how you use my site. This helps us serve you
              content based on preferences you have specified. Please refer to
              my Cookie Policy for more information.
            </p>
            <h3>8. Business transfers</h3>
            <p>
              If me or my assets are acquired, or in the unlikely event that we
              go out of business or enter bankruptcy, I would include data among
              the assets transferred to any parties who acquire us. You
              acknowledge that such transfers may occur, and that any parties
              who acquire us may continue to use your personal information
              according to this policy.
            </p>
            <h3>9. Limits of my policy</h3>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that I have no control over the content and
              policies of those sites, and cannot accept responsibility or
              liability for their respective privacy practices.
            </p>
            <h3>10. Changes to this policy</h3>
            <p>
              At mine discretion, I may change my privacy policy to reflect
              current acceptable practices. We will take reasonable steps to let
              users know about changes via my website. Your continued use of
              this site after any changes to this policy will be regarded as
              acceptance of my practices around privacy and personal
              information.{" "}
            </p>
            <p>
              If I make a significant change to this privacy policy, for example
              changing a lawful basis on which I process your personal
              information, I will ask you to re-consent to the amended privacy
              policy.
            </p>
            <p>
              <strong>Data Controller</strong>
              <br />
              Łukasz Rybka
              <br />
              lukasz@cloudcorridor.com
            </p>
            <p>
              <strong>Data Protection Officer</strong>
              <br />
              Łukasz Rybka
              <br />
              lukasz@cloudcorridor.com
            </p>
            <p>This policy is effective as of 1 May 2018.</p>
          </div>
        </div>
      </article>
    </main>
  </Layout>
)

export default IndexPage
